<script>
    /***
     Name: select
     Code: sa0ChunLuyu
     Time: 2021/11/27 11:06
     Remark:
     */
    export default {
        data() {
            return {}
        },
        mounted() {
        },
        methods: {
            handleChange(value) {
                console.log(`selected ${value}`);
            },
            handleBlur() {
                console.log('blur');
            },
            handleFocus() {
                console.log('focus');
            },
            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },
        },
    }
</script>
<template>
    <div>
        <div><b>下拉菜单 自动填写</b></div>
        <a-select show-search placeholder="Select a person" option-filter-prop="children" style="width: 200px" :filter-option="filterOption" @focus="handleFocus" @blur="handleBlur" @change="handleChange">
            <a-select-option value="jack">
                Jack
            </a-select-option>
            <a-select-option value="lucy">
                Lucy
            </a-select-option>
            <a-select-option value="tom">
                Tom
            </a-select-option>
        </a-select>
    </div>
</template>
<style scoped>

</style>
