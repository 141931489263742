<script>
/***
 Name: auto_input
 Code: sa0ChunLuyu
 Time: 2021/11/27 10:59
 Remark: 自动填写
 */
import CascaderComponent from './cascader.vue'
import SelectComponent from './select.vue'
export default {
  components:{
    CascaderComponent,
    SelectComponent
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {}
}
</script>
<template>
  <div>
    <CascaderComponent></CascaderComponent>
    <SelectComponent></SelectComponent>
  </div>
</template>
<style scoped>

</style>
